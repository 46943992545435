<template>
  <div class="equipment-settings">
    <b-card>
      <b-tabs card>
        <b-tab active>
          <template #title>
            <feather-icon icon="SlidersIcon" />
            Ustawienia sprzętu
          </template>

          <vs-loader
            full
            style="top: 0"
            v-if="isLoading"
          />

          <equipment-settings-details />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
} from 'bootstrap-vue';
import { provide, ref } from 'vue';
import useBaseApi from '@/hooks/base/useBaseApi';
import { useRouter } from '@/@core/utils/utils';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import EquipmentSettingsDetails from './EquipmentSettingsDetails.vue';

export default {
  name: 'EquipmentSettings',
  setup() {
    const { route } = useRouter();
    const machineId = route.value.params.id;
    const machine = ref({ id: machineId });
    const isLoading = ref(true);

    useBaseApi()
      .fetchResource(machineId)
      .then(({ data }) => {
        machine.value = {
          ...data.data,
          id: data.data.id,
          base_resource_daily_cost: data.data.actual_daily_cost,
          base_group_id: data.data.last_activity?.base_group_id ?? null,
          base_technology_id: data.data?.technologies?.map((t) => t.id) ?? [],
        };
      })
      .finally(() => {
        isLoading.value = false;
      });

    provide('machine', machine);

    return { isLoading };
  },
  components: {
    BTabs,
    BTab,
    BCard,
    EquipmentSettingsDetails,
    VsLoader,
  },
};
</script>

<style scoped>

</style>
