var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "equipment-settings"
  }, [_c('b-card', [_c('b-tabs', {
    attrs: {
      "card": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "SlidersIcon"
          }
        }), _vm._v(" Ustawienia sprzętu ")];
      },
      proxy: true
    }])
  }, [_vm.isLoading ? _c('vs-loader', {
    staticStyle: {
      "top": "0"
    },
    attrs: {
      "full": ""
    }
  }) : _vm._e(), _c('equipment-settings-details')], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }